<template>
  <div class="container">
    <div class="top-btn">
      <div
        class="back-button"
        @click="onback"
        :style="{ 'padding-top': statusBarHeight }"
      >
        <i class="icon-leftarrow"></i>
        <span class="title">返回</span>
      </div>
    </div>
    <div class="text-body">
      <div>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              font-family: Montserrat;
              font-size: 15pt;
              font-weight: bold;
              text-decoration: underline;
            "
            >iMeddy</span
          ><span
            style="
              font-family: Montserrat;
              font-size: 15pt;
              font-weight: bold;
              text-decoration: underline;
            "
          >
          </span
          ><span
            style="
              font-family: 新細明體;
              font-size: 15pt;
              font-weight: bold;
              text-decoration: underline;
            "
            >禮品</span
          ><span
            style="
              font-family: 新細明體;
              font-size: 15pt;
              font-weight: bold;
              text-decoration: underline;
            "
            >優惠</span
          ><span
            style="
              font-family: Montserrat;
              font-size: 15pt;
              font-weight: bold;
              text-decoration: underline;
            "
            >條款及細則</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1.</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">閣下於</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">
            成功登記後，受限於此等《</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">
            條款及細則》的現行版本。您可於</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">&nbsp; </span
          ><span style="font-family: Montserrat; font-size: 12pt">App</span
          ><span style="font-family: Montserrat; font-size: 12pt">
            查閱此等《</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">
            條款及細則》的現行版本。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">&nbsp;</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">2</span
          ><span style="font-family: Montserrat; font-size: 12pt">.</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">登記</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >必須遵守所有</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >相關的條款及細則，包括但不限於《</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則》（可於</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> App</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >查閱）。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">&nbsp;</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">3</span
          ><span style="font-family: Montserrat; font-size: 12pt">.</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">如果優惠</span
          ><span style="font-family: Montserrat; font-size: 12pt">券</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >、電子優惠</span
          ><span style="font-family: Montserrat; font-size: 12pt">券</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >、優惠碼及/或迎新禮品由第三方供應商提供，我們則不保證用戶可成功兌換
            / 使用任何相關優惠</span
          ><span style="font-family: Montserrat; font-size: 12pt">券</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >、電子優惠</span
          ><span style="font-family: Montserrat; font-size: 12pt">券</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >、優惠碼及/或迎新禮品，亦不保證任何迎新禮品的質素，我們不會承擔與之相關的任何責任。一切有與之的爭議，請直接與相應供應商自行解決。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">&nbsp;</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">4</span
          ><span style="font-family: Montserrat; font-size: 12pt">.</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >如果我們認為任何人違反或可能已違反任何</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的相關條款及細則，我們有權在不損害在</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的所有相關條款及細則下或我們可享有的任何其他權利和補救的情況下，立即取消該人士在</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的登記、終止該人士的</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >帳戶、撤回該人士應享有的</span
          ><span style="font-family: Montserrat; font-size: 12pt">任何</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >禮品和優惠（全部或部分）及/或採取我們認為適當的任何其他行動，恕</span
          ><span style="font-family: Montserrat; font-size: 12pt">不</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >另行通知。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">&nbsp;</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">5</span
          ><span style="font-family: Montserrat; font-size: 12pt">.</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >我們擁有所有有關送出和使用任何禮品之所有最終決定權。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">&nbsp;</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">6</span
          ><span style="font-family: Montserrat; font-size: 12pt">.</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >我們可能不時更新此條款及細則而毋須事先通知。除非另有說明，經修訂的條款及細則將於張貼日起生效。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">&nbsp;</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">7</span
          ><span style="font-family: Montserrat; font-size: 12pt">.</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >任何圖片（如有）僅供參考。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">&nbsp;</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">&nbsp;</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="
              font-family: Montserrat;
              font-size: 15pt;
              font-weight: bold;
              text-decoration: underline;
            "
            >iMeddy</span
          ><span
            style="
              font-family: Montserrat;
              font-size: 15pt;
              font-weight: bold;
              text-decoration: underline;
            "
            >條款及細則</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">閣下於</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >登記並持續登記及／或使用其下任何服務，即表示閣下同意受</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的所有現行條款及細則約束，包括此等條款及細則、《</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt">私隱聲</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >明》及適用的《個人資料收集聲明》之現行版本（</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的所有現行條款及細則統稱為「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >iMeddy</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >條款及細則</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」）。閣下可於</span
          ><span
            style="color: #212529; font-family: Montserrat; font-size: 12pt"
          >
            iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> App</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >查閱此等條款及細則的最新版本。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >1. </span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >iMeddy&nbsp;
          </span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">是由</span
          ><span style="font-family: Montserrat; font-size: 12pt">本公司</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創醫療</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >服務有限公司</span
          ><span style="font-family: Montserrat; font-size: 12pt">（「</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」）營運的平台。</span
          ><span style="font-family: Montserrat; font-size: 12pt"> iMeddy </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >是一個促進香港註冊醫生／其他醫療專業人員為身處於香港的合資格用戶提供醫療和健康護理服務的平台。</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">及</span
          ><span style="font-family: Montserrat; font-size: 12pt"> iMeddy </span
          ><span style="font-family: Montserrat; font-size: 12pt">均未獲</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >許可或註冊以提供醫療／健康護理意見及服務。</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">／</span
          ><span style="font-family: Montserrat; font-size: 12pt"> iMeddy </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >與閣下之間的關係不構成醫生</span
          ><span style="font-family: Montserrat; font-size: 12pt">–</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >病人／醫療專業人員</span
          ><span style="font-family: Montserrat; font-size: 12pt">–</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >病人關係，而醫生</span
          ><span style="font-family: Montserrat; font-size: 12pt">–</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >病人／醫療專業人員</span
          ><span style="font-family: Montserrat; font-size: 12pt">–</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >病人關係僅於</span
          ><span style="font-family: Montserrat; font-size: 12pt"> iMeddy </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >上之醫生／醫療專業人員與病人之間存在（該關係是於醫生／醫療專業人員建立之範圍內）。</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">及</span
          ><span style="font-family: Montserrat; font-size: 12pt"> iMeddy </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >並不承擔任何專業醫療／健康護理機構的職責，並不行醫或提供健康護理服務，亦不提供任何醫療或健康護理意見、建議或諮詢或進行藥物處方或其他醫療、健康護理或臨床服務，而在醫生／醫療專業人員透過</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt">iMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >提供服務過程中的任何上述各項僅專門由醫生／醫療專業人員及本公司的醫療合作夥伴承辦商（例如護士及藥劑師）進行。</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">及</span
          ><span style="font-family: Montserrat; font-size: 12pt"> iMeddy </span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >亦不承擔或分擔醫生或醫療專業人員就有關其所提供醫療或健康護理服務質素或適當性的職責或責任，包括提供醫療或健康護理服務的方式（如透過視像諮詢），而該等服務僅基於有關醫生或醫療專業人員獨立行使專業判斷。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">閣下使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >及對其下提供的服務需自行承擔風險。</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >並不以任何方式旨在或有意取代醫生／醫療專業人員與病人之間常規的親身接見服務。醫生／醫療專業人員提供的任何資料、建議、意見、診斷、治療、服務、醫療證明（定義見第4.4條）及／或藥物，乃由該特定醫生／醫療專業人員提供及代表其提供，且僅基於該醫生／醫療專業人員行使其專業判斷。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >是為促進病人與醫生／醫療專業人員之間的連</span
          ><span style="font-family: Montserrat; font-size: 12pt">繫</span
          ><span style="font-family: Montserrat; font-size: 12pt">而提供。</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">及</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"> (</span
          ><span style="font-family: Montserrat; font-size: 12pt">i</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >) 並不推薦或認可任何特定醫生／醫療專業人員；及 (ii)
            在適用法律允許的最大範圍內，無論出於何種原因，概不會對該等醫生／醫療專業人員透過</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >提供或給予的任何資料、建議、意見、診斷、治療、服務、醫療證明及／或藥物負責或承擔責任，亦不會予以認可或支持或推薦。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1.4</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >在適用法律允許的最大範圍內，若閣下的任何處方藥或醫療證明有任何使用或誤用，或若任何人獲准使用、服用及／或施用閣下的處方藥或醫療證明（不論閣下是否同意或知情），</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">及</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >均毋須以任何方式負責或承擔責任。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1.5</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >不適合需要即時醫療協助的症狀。</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">並不 (</span
          ><span style="font-family: Montserrat; font-size: 12pt">i</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >) 提供緊急醫療服務；或 (ii)
            提供任何急性醫療狀況的護理，且不應這樣使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">。</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >不一定適合所有醫療狀況或症狀。若 (a) 閣下需要緊急醫療協助；(b)
            閣下有或曾有需要立即就醫的症狀，包括但不限於：</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >窒息</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >說話困難</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >自殺或殺人的念頭</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >胸口疼痛</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >嚴重出血</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >錯亂及／或幻覺</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >癲癇發作</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >失去知覺</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >其他嚴重的醫療或類似症狀，</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >呼吸困難</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >骨折</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >嚴重疼痛</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">•</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-style: italic"
            >手腳無力</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <br /><span style="font-family: Montserrat; font-size: 12pt"
            >請考慮召喚救護車或到最近閣下的醫院的急症室就醫。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1.6</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若閣下的狀況或症狀持續或惡化，請尋求其他醫療建議。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1.7</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >僅可透過在允許進行視像通話的智能流動裝置上下載</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式而取用。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >2. 資格</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">2.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若閣下懷孕，您可以於</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >登記並保持登記用戶身份（「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >用戶</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」），但閣下可能不得在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >上就諮詢作預約申請。然而，此限制並不適用於閣下就2019冠狀病毒病測試服務的諮詢作預約申請</span
          ><span style="font-family: Montserrat; font-size: 12pt">（</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >定義見第4.2</span
          ><span style="font-family: Montserrat; font-size: 12pt">（</span
          ><span style="font-family: Montserrat; font-size: 12pt">b</span
          ><span style="font-family: Montserrat; font-size: 12pt">）</span
          ><span style="font-family: Montserrat; font-size: 12pt">條</span
          ><span style="font-family: Montserrat; font-size: 12pt">）</span
          ><span style="font-family: Montserrat; font-size: 12pt">。</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">2.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >在與醫生／醫療專業人員進行視像諮詢時，閣下必須身處香港。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">2.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >在就與醫生諮詢作預約申請時，閣下必須年滿14歲。若閣下未滿14歲，閣下不符合</span
          ><span style="font-family: Montserrat; font-size: 12pt">在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >上與醫生諮詢的資格。儘管有上述年齡限制，與特定醫療夥伴的醫生或特定類型的醫療專業人員諮詢時或有不同的年齡限制。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">2.4</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若閣下在視像諮詢時年滿14歲但未滿18歲，閣下在視像諮詢期間須由一名成年人親身陪同。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">2.5</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >在就諮詢作預約申請時，閣下必須備有並提供有效身份證明文件（例如香港身份證、出生證明書或護照）詳情，以供核實。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >3. 登記</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">3.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >閣下必須登記為</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式之用戶，方可取用服務。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">3.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式可於App Store及Play Store下載。</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式的更新可能不時可供下載。為提升閣下在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >上的體驗，請確保閣下在流動裝置上下載最新版本的</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">3.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">在下載</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式時，閣下會被要求同意</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >存取閣下流動裝置上的某些功能，以確保</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >向閣下提供服務的全面功能。若閣下不作出同意，閣下將無法安裝或使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式或服務的某些功能。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">3.4</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >閣下將須提供若干資料，包括但不限於有效的電郵地址（不接受別名電子郵件地址）及有效的香港手機號碼（手機號碼必須能夠接收和發送短訊，且需留意閣下的流動服務供應商可能會向您徵收使用短訊的費用），並需創設一個密碼，方可在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">登記。</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">3.5</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >每個人只能於一個</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >帳戶下作登記。每</span
          ><span style="font-family: Montserrat; font-size: 12pt">個</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >有效的電郵地址及有效的香港手機號碼只可用作登記一個</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">帳戶。</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">3.6</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >登記，並不保證用戶將能夠預約與</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >上的醫生／醫療專業人員進行視像諮詢。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">3.</span
          ><span style="font-family: Montserrat; font-size: 12pt">7</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若閣下是18歲以下的未成年人，於</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的任何登記必須經閣下的父母／法定監護人同意，而其亦將為閣下的緊急聯絡人，以及代表閣下同意</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則的人。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >4. 服務範圍</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">4.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >是一個以促進香港註冊醫生／醫療專業人員為合資格用戶提供醫療和健康護理服務的平台。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">4.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">有關：</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(a)
            醫療和健康護理諮詢，包括預約安排、任何建議及診斷；以及若醫生／醫療專業人員在諮詢後基於其專業判斷認為有必要及適當，該醫生／醫療專業人員因此而處方／發出的任何處方藥物及／或醫療證明（統稱為「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >醫療諮詢服務</span
          ><span style="font-family: Montserrat; font-size: 12pt">」）；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(</span
          ><span style="font-family: Montserrat; font-size: 12pt">b</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >) （代表身為用戶的閣下）領取及運送任何處方藥物及／或醫療證明</span
          ><span style="font-family: Montserrat; font-size: 12pt">（</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >就醫療諮詢服務，以及實際運送／樣本收取服務</span
          ><span style="font-family: Montserrat; font-size: 12pt">（</span
          ><span style="font-family: Montserrat; font-size: 12pt">統稱為「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >運送服務</span
          ><span style="font-family: Montserrat; font-size: 12pt">」</span
          ><span style="font-family: Montserrat; font-size: 12pt">）</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >的服務（上述服務統稱為「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >第三方服務</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」）由第三方供應商（統稱為「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >第三方供應商</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」）提供，第三方供應商可能包括健康護理供應商（包括參與的醫療合作夥伴）、運送公司、保險公司及／或其他組織。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">4.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">閣下可以</span
          ><span style="font-family: Montserrat; font-size: 12pt">透過</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >閣下的流動裝置經由</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式申請預約透過視像諮詢約見一名香港註冊醫生／醫療專業人員。醫療專業人員或會要求用戶透過視像諮詢向醫生展示用作在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >上預約視像諮詢的身份證明文件（例如其香港身份證），以供醫生／醫療專業人員核實用戶的身份。若用戶不這樣做，醫生／醫療專業人員或會自行決定拒絕用戶進行視像諮詢。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">4.4</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若醫生在諮詢後基於其專業判斷認為有必要及適當，醫生可發出醫療證明（例如病假證明）及／或轉</span
          ><span style="font-family: Montserrat; font-size: 12pt">介</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >信（統稱為「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >醫療證明</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」）及／或向閣下處方藥物。任何該等藥物及醫療證明將會連同一份</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">收據</span
          ><span style="font-family: Montserrat; font-size: 12pt">送遞至</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >閣下的指定地址。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">4.5</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >醫療諮詢服務的運送服務將涵蓋香港島、九龍及新界的地址，惟以下任何地區及地點除外：</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(a) 離島（例如大嶼山、南丫島、長洲、馬灣及珀麗灣），東</span
          ><span style="font-family: Montserrat; font-size: 12pt">涌</span
          ><span style="font-family: Montserrat; font-size: 12pt">除外；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(b)
            可能需要取得有關政府部門許可方可進入的邊境禁區（例如沙頭角、打鼓嶺及落馬洲）;
            及</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(c) 郊野公園、流浮山</span
          ><span style="font-family: Montserrat; font-size: 12pt">及石澳之</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >非市區區域。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">4.</span
          ><span style="font-family: Montserrat; font-size: 12pt">6</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >每次醫療諮詢服務（之任何處方藥物及醫療證明之送遞）。然而，若</span
          ><span style="font-family: Montserrat; font-size: 12pt">送遞員</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >於約定運送／收取時間抵達閣下指定地址，但由於任何原因而未能送達／收取（送遞員的過失所致除外）</span
          ><span style="font-family: Montserrat; font-size: 12pt">（</span
          ><span style="font-family: Montserrat; font-size: 12pt">例如 (</span
          ><span style="font-family: Montserrat; font-size: 12pt">i</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >) 當送遞員於約定運送／收取時間抵達時，沒有人於運送地址；(ii)
            若用戶為未成年人，並無成年人在運送地址收貨；或(iii)
            若用戶遺失樣本容器及須要安排重新</span
          ><span style="font-family: Montserrat; font-size: 12pt">送遞新</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的樣本容器</span
          ><span style="font-family: Montserrat; font-size: 12pt">）</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >而須另行安排運送／收取時間，則</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的客戶服務（「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >客服</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」，其工作人員將透過</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式中的「訊息」，就有關不涉及行醫的事項作為與用戶的主要聯絡點）會聯絡閣下，而閣下將須向</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >或直接向本公司的運送合作夥伴（第三方供應商）支付可能需要的運送／收取附加費。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">4.</span
          ><span style="font-family: Montserrat; font-size: 12pt">7</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的營業時間及其下服務（包括但不限於運送服務）將在惡劣天氣狀況下暫停，包括但不限於8號或以上颱風信號、黑色暴雨警告、嚴重的洪水或由於惡劣天氣造成的道路阻塞或封閉。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">4.8</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >iMeddy的藥物運送承諾為24小時內送達。本公司將盡力為客戶提供送藥上門服務，唯一
            些不可抗力的原因或情況包括但不限於天災、政府行為、火災、水災、流行病、檢疫限制、
            罷工、公民動亂、勞動力或物資短缺、貨物禁運、異常惡劣天氣、電力故障、電訊或互聯網主
            幹中斷、互聯網接入供應商故障，或本公司無法控制的其他類似原因，將有機會影響運送時間。
            若發生以上情況，本公司會盡快在解決其原因後立即安排送藥。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >5. 預約諮詢</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">5.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >當閣下就與醫生／醫療專業人員進行諮詢作預約申請時，閣下須上載閣下有效的香港身份證、護照或出生證明書的清晰圖像至</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式。若閣下不這樣做，閣下的預約申請可能會被拒絕。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">5.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >當閣下就與醫生／醫療專業人員進行諮詢作預約申請時，閣下或可選擇普通科或專科醫生／醫療專業人員（其診金或會不同）。閣下亦可能有多於一個的醫生／醫療專業人員（他們可能來自同一間或不同的醫院、醫療診所及／或健康護理提供者）選擇。當閣下在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >選擇預約的醫生／醫療專業人員時，閣下明白並接受以下各項：</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(a) 各名醫生／醫療專業人員</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的診金可能</span
          ><span style="font-family: Montserrat; font-size: 12pt">不同；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(b) 若醫生僅基於其專業判斷認為有必要及適當，醫生</span
          ><span style="font-family: Montserrat; font-size: 12pt">的診金將</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >包括最多三（3）日基本藥物，閣下將須為任何額外或指定藥物支付藥費；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(c) </span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >不會以任何方式推薦或認可任何醫院、醫療診所、健康護理提供者、醫生或其他醫療專業人員，提供予閣下預約選擇的任何醫生／醫療專業人員，不應以任何方式被詮釋為</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >推薦或認可該等醫生／醫療專業人員；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(d) </span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >網站及應用程式上有關任何醫院、醫療診所、健康護理提供者、醫生及／或醫療專業人員的任何資料，乃由有關醫院、醫療診所、健康護理提供者、醫生及</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >／或醫療專業人員直接提供，而</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >毋須以任何方式對該等資料的提供、準確性或完整性負責；及</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(e)
            閣下自行負責選擇想要預約的醫生／醫療專業人員，包括有關閣下可能有的任何症狀適用性。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">5.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >閣下須支付預約費用，方可就與</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >上的醫生／醫療專業人員進行視像諮詢作預約申請，有關預約費用將於諮詢完成後，從閣下使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >服務的最終應付金額中完全扣除。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">5.4</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若閣下在預定的諮詢開始時間不出現或遲到，預約費用將被沒收。若閣下在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >上作另一次預約申請，閣下將須重新支付預約費用。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">5.5</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >如閣下需要更改已確定的預約，閣下必須於</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >取消已確定的預約及作新的預約申請。閣下於新的預約申請未必能夠預約相同的醫生／醫療專業人員。如果閣下取消預約，</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >醫生有權沒收</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >閣下的預約費用。如果您在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >上作新的預約申請，則必須重新支付預約費用。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">5.6</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若在開始與閣下進行諮詢後，醫生／醫療專業人員（僅基於其專業判斷）認為閣下基於任何原因而實際上不適合透過</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式進行諮詢，而該醫生／醫療專業人員在諮詢上花費的總時間不超過兩（2）分鐘（由</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >系統計算），則閣下已支付的預約費用將不會退還予閣下。然而，若閣下在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的簡短諮詢結束後12小時內（或</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >告知閣下的其他時間段），親身到該醫生／醫療專業人員的實體醫院／診所就醫，則該醫生／醫療專業人員的實體醫院／診所應</span
          ><span style="font-family: Montserrat; font-size: 12pt">從診金</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >及／或閣下於該實體醫院／診所進行的諮詢的其他應付費用中，扣除相等於該預約費用的金額。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">5.7</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若在開始與閣下進行諮詢後，醫生／醫療專業人員（僅基於其專業判斷）認為閣下基於任何原因而實際上不適合透過</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式進行諮詢，而該醫生／醫療專業人員在諮詢上花費的總時間超過兩（2）分鐘（由</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >系統計算），則該醫生／醫療專業人員</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >有權按一次</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >完整諮詢向閣下收費。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">5.8</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >無法保證閣下要求的醫生／醫療專業人員可在閣下想要的時間進行諮詢。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >6. 醫療／健康護理諮詢及運送</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">6.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >任何醫療諮詢是在閣下與相關醫生／醫療專業人員之間進行，受限於該醫生／醫療</span
          ><span style="font-family: Montserrat; font-size: 12pt">專</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >業人員信納閣下</span
          ><span style="font-family: Montserrat; font-size: 12pt">適合</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >進行遙距諮詢</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >互動，而毋須</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的任何參與或看見。然而，若閣下在諮詢時年滿14歲但未滿18歲，閣下在諮詢期間須由一名成年人親身陪同。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">6.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">
            醫生／醫療專業人員在諮詢期間，不得對諮詢的任何部分或IMeddy應用程式之
            界面的任何部分進行任何截圖、拍照、錄音或錄像，而閣下（及在閣下諮詢期間
            陪同閣下的任何人）亦不得作出上述任何行為；除非 (i)
            在閣下的合理要求情
            況下，醫生／醫療專業人員獨自根據其專業判斷認為是有必要和適當的；或
            (ii)
            醫生／醫療專業人員獨自根據其專業判斷認為是有必要和適當的，並在事前醫生／
            醫療專業人員已得到閣下的同意。上述的任何截圖、拍照、錄音及/或錄像會構成
            閣下的醫療記錄的一部分，並由該醫生／醫療專業人員及/或其所屬醫院或診所保留，
            iMeddy
            及其他第三方不會對諮詢的任何部分進行任何截圖、拍照、錄音或錄像。
          </span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">6.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若醫生在與閣下進行諮詢後基於其專業判斷認為有必要及適當，其可能為閣下處方藥物及／或發出醫療證明。</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >不以任何方式保證醫生將會在諮詢後為閣下處方任何藥物及／或發出任何醫療證明。任何處方藥物均不可退還、交換或退款。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">6.4</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若醫生在諮詢後為閣下處方藥物及／或發出醫療證明，</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >客服將聯絡</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >閣下以安排把藥物及／或醫療證明，連同</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >收據，全部放在一個</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >運送包（「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >運送包</span
          ><span style="font-family: Montserrat; font-size: 12pt">」）中，</span
          ><span style="font-family: Montserrat; font-size: 12pt">送遞至</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >閣下的指定地址。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">6.5</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >為使在諮詢後提取及</span
          ><span style="font-family: Montserrat; font-size: 12pt">送遞任何</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >處方藥物，閣下須授權</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的運送合作夥伴的指定</span
          ><span style="font-family: Montserrat; font-size: 12pt">送遞員</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >代表閣下從閣下進行諮詢的醫生的相關醫院或診所提取閣下的藥物並</span
          ><span style="font-family: Montserrat; font-size: 12pt">送遞予</span
          ><span style="font-family: Montserrat; font-size: 12pt">閣下。</span
          ><span style="font-family: Montserrat; font-size: 12pt">如有需要</span
          ><span style="font-family: Montserrat; font-size: 12pt">客服將於</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >諮詢後就有關授權及運送安排與閣下聯絡。為使運送包可</span
          ><span style="font-family: Montserrat; font-size: 12pt">送遞至</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >閣下的指定地址，閣下須</span
          ><span style="font-family: Montserrat; font-size: 12pt">在預</span
          ><span style="font-family: 新細明體; font-size: 12pt">約</span
          ><span style="font-family: Montserrat; font-size: 12pt">時</span
          ><span style="font-family: Montserrat; font-size: 12pt">透過</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">應用程式</span
          ><span style="font-family: Montserrat; font-size: 12pt">同意</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >作出有關授權。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">6.6</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >為使運送包得以</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >送遞，客服亦</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >會向閣下發送一個獨特的檔案號碼，有關檔案號碼必須在閣下的指定地址提取運送包時，向指定</span
          ><span style="font-family: Montserrat; font-size: 12pt">送遞員</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >出示或向其告知。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">6.7</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >閣下應親自在指定地址提取運送包。然而，若閣下基於任何原因而無法親自提取，請確保接收運送包的人已年滿18歲並獲閣下正式授權，且能夠向指定</span
          ><span style="font-family: Montserrat; font-size: 12pt">送遞員</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >出示或向其告知檔案號碼。提取收運送包的人透過能夠出示或告知閣下的檔案號碼，即表示該指定</span
          ><span style="font-family: Montserrat; font-size: 12pt">送遞員</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應視提取運送包的人已年滿18歲，並獲正式授權代表閣下提取運送包，而毋須核實其身份。閣下的獲授權人將須簽署第</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >6.5條提述的</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >紙質授權表格（該簽署亦表示閣下的獲授權人已代表閣下收妥運送包）。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">6.8</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >有關如何服用或施用任何處方藥的說明，將於藥物包裝上註明。對於任何使用或誤用任何處方藥物或醫療證明，或若任何人獲准使用、服用及／或施用處方藥或醫療證明（不論閣下是否同意或知情），</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >概不以任何方式負責或承擔任何責任。</span
          >
        </p>

        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">6.9</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若提供視診服務的醫生/醫療專業人員/或其所屬的醫院或診所只提供藥物處方，
            本人同意並授權iMeddy
            及其運送合作伙伴的指定送遞員到iMeddy合作的其他診所/醫務中
            心或註冊藥房按照在視像問診服務開出的藥物處方配藥，該合作的醫務中心或註冊藥房負責
            向病人解釋、指導用藥並承擔其配藥的責任及醫療風險，
            如對於處方藥物有任何問題，iMeddy需向其合作伙伴提供聯絡方法，讓該合作的醫務
            中心或註冊藥房可向開出處方紙的醫生/醫療專業人員/或其所屬的醫院或診所查詢。</span
          >
        </p>

        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">6.10</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >閣下明白在諮詢後，醫生的診斷將於</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式提供（見下文第</span
          ><span style="font-family: Montserrat; font-size: 12pt">7</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >.1條）。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >7</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >. 紀錄</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">7</span
          ><span style="font-family: Montserrat; font-size: 12pt">.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式中的「</span
          ><span style="font-family: 新細明體; font-size: 12pt"
            >問診記錄-詳情</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」功能乃為儲存及向閣下提供有關閣下使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的資料而設，當中可能載有醫生診斷、如何服用或施用任何處方藥物的說明及閣下的付款紀錄。任何此類紀錄僅供參考。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">7</span
          ><span style="font-family: Montserrat; font-size: 12pt">.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >在諮詢後，閣下的醫療記錄將保存於醫生的診所管理系統中。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">7</span
          ><span style="font-family: Montserrat; font-size: 12pt">.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >醫生全權負責在撰寫閣下的診斷時自行遵守適用法律及監管規定。若診斷中提供的任何資料為誤導、過時或不準確，</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >概不以任何方式負責。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >8</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >. 付款</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">8</span
          ><span style="font-family: Montserrat; font-size: 12pt">.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >閣下承諾就使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >下的服務支付所有收費及費用（統稱為「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >收費</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」），其可能包括但不限於預約費用、</span
          ><span style="font-family: Montserrat; font-size: 12pt">診金</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >、藥費及運送費。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">8</span
          ><span style="font-family: Montserrat; font-size: 12pt">.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >閣下確認並同意，某些收費將由本公司代表本公司的第三方供應商收取，而該收取不會以任何方式損害</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >僅作為一個促進向合資格用戶提供醫療諮詢的平台之立場。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">8</span
          ><span style="font-family: Montserrat; font-size: 12pt">.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >本公司可透過計費代理向閣下計費。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">8</span
          ><span style="font-family: Montserrat; font-size: 12pt">.4</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若閣下不支付任何到期收費，在不損害本公司可能享有的任何其他權利之情況下，閣下須按相等於香港上海滙豐銀行有限公司當時港元最優惠貸款利率加2%的年利率，就逾期款項向本公司支付利息，直至全額付清（判決之前及之後）。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">8</span
          ><span style="font-family: Montserrat; font-size: 12pt">.5</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若閣下不支付任何到期收費，本公司或會要求債務追收代理代表本公司收取有關款項，而閣下將對本公司承擔本公司為聘請有關代理收取款項而可能合理產生的任何費用及開支。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">8</span
          ><span style="font-family: Montserrat; font-size: 12pt">.6</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >除本公司有明顯錯誤或</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則另有指明外，在任何情況下，所有向本公司之付款均不得退還予閣下。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >9</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >. 本公司的權利</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">9</span
          ><span style="font-family: Montserrat; font-size: 12pt">.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">在不損</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則或在其他情況下給予本公司的任何其他權利及補償之情況下，本公司有權隨時作出以下事項，而不論有否發出事先通知：</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(a) 更改</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則的任何條款及細則；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(b) 更改</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的範圍、暫停或限制</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的全部或任何部分；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(c) 取消或終止</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的全部或任何部分；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(d) 取消任何用戶於</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的登記；及／或</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(e) 限制、暫停、拒絕或取消任何用戶取用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的任何部分。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">9</span
          ><span style="font-family: Montserrat; font-size: 12pt">.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >尤其，在不損</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則或在其他情況下給予本公司的任何其他權利及補償之情況下，如出現下列情況，本公司有權立即取消任何用戶於</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的登記、終止任何用戶的</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >帳戶、撤回用戶應有的任何禮品或特權（全部或部分）及/或採取本公司認為適當的其他任何行動，而毋須作事先通知：</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(a) 閣下違反任何</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(b) 本公司懷疑閣下或任何人士於閣下的</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >帳戶（包括但不限於登記過程中）進行任何欺詐、不誠實使用、濫用或未經授權的活動，無論閣下是否同意或已知悉該等欺詐、不誠實使用、濫用或未經授權的活動；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(c)
            閣下在指稱本公司或本公司的任何業務合作夥伴用以傳送譭謗資料的任何法律行動或訴訟中被列為被告人，或閣下</span
          ><span style="font-family: Montserrat; font-size: 12pt">就該指稱</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >遭威脅將被起訴；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(d) 閣下或第三方使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">而</span
          ><span style="font-family: Montserrat; font-size: 12pt">：</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(</span
          ><span style="font-family: Montserrat; font-size: 12pt">i</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >)
            作不正當用途，或傳送發出非應邀的宣傳資料或任何猥褻或不雅的資料；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(ii)
            不論是否根據任何適用法律（定義見第11.1(d)條）與否，屬於未經授權、欺詐、不誠實、不合法或非法方式；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(iii)
            其使用方式構成閣下侵犯任何人士的權利（包括但不限於知識產權及保密的權利），或違反或侵害依合同法、侵權法或其他法律下，須向第三方承擔的任何義務或責任；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(e) 於登記用戶逝世後；及/或</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(f)
            根據任何適用法律，本公司（或本公司的任何業務合作夥伴）被禁止提供</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >或其下任何服務；</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">9</span
          ><span style="font-family: Montserrat; font-size: 12pt">.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">若閣下於</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的登記被取消或閣下的</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >帳戶被終止，閣下可能無法再取用其下的服務。任何已作出的預約將會被取消，而已支付的任何預約費用將不設退還。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">9</span
          ><span style="font-family: Montserrat; font-size: 12pt">.4</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >閣下確認並同意，第10條（本公司的權利）、第1</span
          ><span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條（第三方供應商）、第1</span
          ><span style="font-family: Montserrat; font-size: 12pt">3</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條（彌償）、第1</span
          ><span style="font-family: Montserrat; font-size: 12pt">4</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條（責任限制）及第1</span
          ><span style="font-family: Montserrat; font-size: 12pt">5</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條（一般條款）於閣下在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的登記取消或</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >或其下任何服務終止後仍然生效。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >1</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >0</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >. 閣下使用</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >IMeddy</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">0</span
          ><span style="font-family: Montserrat; font-size: 12pt">.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">閣下：</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(a) 僅獲授權根據</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >作自身個人及非商業用途；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(b) 不會允許任何第三方未經本公司事先書面同意而取用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >或使用其下任何服務；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(c)
            不得及不得允許任何其他人士出售、重製、複製、分發、發布、修改</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式、伺服器（定義見第11.2條）或</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >下任何服務之任何部分，及／或基於</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式或伺服器而編製衍生作品；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(d) 將遵守有關</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的所有適用法律、規則及法規（統稱為「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >適用法律</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」），以及所有</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(e) 將提供在登記</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >及支付收費、預約醫生進行視像諮詢及使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >所需的有關閣下的真實、準確、最新及完整資料（「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >用戶資料</span
          ><span style="font-family: Montserrat; font-size: 12pt">」）；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(f) 將維持並及時更新閣下已遞交予</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的用戶資料，並使其真實、準確、最新及完整；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(g)
            不會提供不真實、不準確、過時或不完整的任何資料。若有合理理由懷疑任何資料為不真實、不準確、過時或不完整，本公司有權暫停或限制用戶取用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >，並拒絕任何及所有現時或未來使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(h) 將及時向</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >報告任何違反任何</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則或適用法律的情況；及</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(</span
          ><span style="font-family: Montserrat; font-size: 12pt">i</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >)
            將保護閣下的登錄資料及密碼，並確保其不會被披露或提供予任何其他方或人士。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">0</span
          ><span style="font-family: Montserrat; font-size: 12pt">.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >閣下不得以任何方式截取、干擾或篡改</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的任何部分</span
          ><span style="font-family: Montserrat; font-size: 12pt">（</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >包括但不限於連接至或經由</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >連接的任何伺服器、網絡、軟件、硬件或設備</span
          ><span style="font-family: Montserrat; font-size: 12pt">（</span
          ><span style="font-family: Montserrat; font-size: 12pt">統稱為「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >伺服器</span
          ><span style="font-family: Montserrat; font-size: 12pt">」</span
          ><span style="font-family: Montserrat; font-size: 12pt">）</span
          ><span style="font-family: Montserrat; font-size: 12pt">及</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">應用程式</span
          ><span style="font-family: Montserrat; font-size: 12pt">）</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >，且不得直接或間接使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">或允許</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >被用作以下用途：</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(a) 用作業務或商業用途；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(b) (b)
            作出或實施可能屬非應邀的、冒犯的、誹謗的、不雅的、淫褻的、恐嚇的、滋擾的、不道德的、侵權的、非法的、不合法的、不誠實的、</span
          ><span style="font-family: Montserrat; font-size: 12pt">嚇詐行為</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >，或侵犯任何第三方或知識產權權利；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(c)
            發出非應邀的、冒犯的、誹謗的、不雅的、淫褻的、恐嚇的、滋擾的或</span
          ><span style="font-family: Montserrat; font-size: 12pt">嚇詐訊息</span
          ><span style="font-family: Montserrat; font-size: 12pt">或內容；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(d) 使任何他方或人士感到滋擾、不便或有不必要的焦慮；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(e) 作出本公司自行決定屬不適當的任何其他行動；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(f) 收集</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >用戶的資料；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(g) 移除</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >中載有的任何版權、商標或其他專有權利通告；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(h) </span
          ><span style="font-family: Montserrat; font-size: 12pt">以</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">／</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >提供的說明或界面以外的任何方式或方法取用或嘗試取用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(</span
          ><span style="font-family: Montserrat; font-size: 12pt">i</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >) 限制或禁止任何其他人士使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >，包括但不限於透過侵入、闖入或嘗試侵入或闖入的方式，或對</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >造成任何損害；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(j)
            修改、編彙、再許可、重製、分發、複製、利用、翻譯、出售、逆向工程、解編或分</span
          ><span style="font-family: Montserrat; font-size: 12pt">柝</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式及伺服器的任何部分；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(k)
            使用任何機器人、蜘蛛程式、網站搜尋／檢索應用程式，或其他手動或自動裝置或流程，以檢索、索引、「數據挖掘」或以其他方式重製、破壞或規避 </span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式及伺服器的導航結構</span
          ><span style="font-family: Montserrat; font-size: 12pt">或呈示</span
          ><span style="font-family: Montserrat; font-size: 12pt">；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(l)
            以任何方式或透過任何媒介下載、複製、儲存、擷取、分發、分享或重新定向</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">
            應用程式及伺服器的任何部分；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(m) 把包含任何病毒、蠕蟲、定時炸彈、特</span
          ><span style="font-family: Montserrat; font-size: 12pt">洛</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >伊木馬或其他有害或破壞性組件的任何軟件或其他材料加入到</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式及伺服器中；及</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(n) 偽造標題或以其他方式操縱標識符，以掩蓋透過</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式及伺服器傳輸的任何內容之來源。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">0</span
          ><span style="font-family: Montserrat; font-size: 12pt">.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若閣下想取消閣下於</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的登記或終止閣下的</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >帳戶，請填寫指定終止表格並交回本公司。閣下可透過向客服</span
          ><span style="font-family: Montserrat; font-size: 12pt">（</span
          ><span style="font-family: Calibri; font-size: 12pt">E</span
          ><span style="font-family: Calibri; font-size: 12pt"
            >nquiry@tc-doctor.com</span
          ><span style="font-family: Montserrat; font-size: 12pt">）</span
          ><span style="font-family: Montserrat; font-size: 12pt">發送</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >電郵或致電</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">熱線</span
          ><span style="font-family: Montserrat; font-size: 12pt">3611</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span
          ><span style="font-family: Montserrat; font-size: 12pt">8428</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >取得終止表格。在收到填妥的終止表格後，閣下的</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >帳戶一般會在七（7）</span
          ><span style="font-family: Montserrat; font-size: 12pt">個</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >工作日內終止。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >1</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >1</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >. 第三方供應商</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >閣下確認，</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的部分服務（包括醫療諮詢服務）由第三方供應商貢獻、提供及／或維護。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >在法律允許的最大範圍內，閣下確認並同意，</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">（作為</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的平台營運商）：</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(a)
            並無就有關第三方供應商提供或給予的任何服務作出任何明示或暗示的聲明、陳述或保證；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(b)
            不同意、不反對、不批准及不認可任何第三方供應商提供的任何建議、材料或服務；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(c)
            毋須以任何方式對任何第三方供應商提供的任何建議、材料及／或服務負責；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(d) 不對各第三方供應商負責的服務表現作出任何保證；</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(e)
            對於任何使用、無法使用或誤用或倚賴任何第三方供應商提供的建議、材料或服務所</span
          ><span style="font-family: Montserrat; font-size: 12pt">引致或與</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >之相關的任何直接或間接損失、費用或損害（包括但不限於後隨損失、利潤損失或損害），或用戶或任何他方或人士由於任何第三方供應商提供的任何建議、材料或服務或與之相關而可能遭受或產生的任何損失、費用或損害，概</span
          ><span style="font-family: Montserrat; font-size: 12pt">無或概不</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >承擔任何責任、義務或負責；及</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(f) 對於第三方供應商向用戶提供的醫療諮詢服務所</span
          ><span style="font-family: Montserrat; font-size: 12pt">引致或與</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >之相關的用戶的任何損失、損害、財產損毀、人身傷亡，概</span
          ><span style="font-family: Montserrat; font-size: 12pt">無或概不</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >承擔任何責任、義務或負責。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >1</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >2</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >. 個人資料</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">2</span
          ><span style="font-family: Montserrat; font-size: 12pt">.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >登記及取用其下服務時，閣下或須向本公司提供若干個人資料</span
          ><span style="font-family: Montserrat; font-size: 12pt">（</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >定義見香港法例第486章《個人資料（私隱）條例》）。閣下對於閣下所提供的個人資料享有若干權利。</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >將盡其最大商業努力及謹慎，以保障閣下的個人資料。閣下須同意</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式中載列的《個人資料收集聲明》，以及《</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >私隱聲明》（載於</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式及</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >網站），方可在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >登記並取用其下的服務。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >1</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >3</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >. 彌償</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">3</span
          ><span style="font-family: Montserrat; font-size: 12pt">.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >閣下同意就任何</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >、其服務供應商、承辦商、次承辦商、特許人及代理商（包括但不限於第三方供應商）及其各自之職員、董事及僱員（統稱為「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >獲彌償方</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」）由於或有關以下各項而遭受或產生的任何訴訟、責任、費用、申索、損失、損害、程序及／或開支彌</span
          ><span style="font-family: Montserrat; font-size: 12pt">償獲彌償</span
          ><span style="font-family: Montserrat; font-size: 12pt">方：</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(a) 閣下在</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的登記及／或閣下使用其下的任何服務；及</span
          ><br /><br /><span style="font-family: Montserrat; font-size: 12pt"
            >(b) 閣下及／或以閣下的</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >帳戶使用服務的其他方或人士（不論閣下有否授權或知情）任何違反或不遵守任何</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt">
            條款及細則。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >1</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >4</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >. 責任限制</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">4</span
          ><span style="font-family: Montserrat; font-size: 12pt">.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >在不限制本條款及細則其他條款的前提下，</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >上的所有內容及其下提供的</span
          ><span style="font-family: Montserrat; font-size: 12pt">服務乃按</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >「現狀」及「現有」基礎向閣下提供，並無任何明示或暗示的保證或情況，而有關</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >及其下服務的所有陳述及</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >保證均在任何</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >適用法律允許的最大範圍內予以排除。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">4</span
          ><span style="font-family: Montserrat; font-size: 12pt">.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >在法律允許的最大範圍內，對於閣下取用、倚賴或使用或無法使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >之任何部分（包括任何第三方服務）所引致的任何直接、間接、後隨、附帶、特別、懲罰性或偶發性損失、損毀或損害（包括但不限於數據損壞、利潤、商譽、議價或機會損失或預期儲蓄損失或任何其他損失），不論是基於保證、合約、侵權、疏忽或任何其他法律理論，及</span
          ><span style="font-family: Montserrat; font-size: 12pt">不論</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">或</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >是否知道或合理地應已知道該等損害的可能性，</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">及</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >概不承擔任何責任。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">4</span
          ><span style="font-family: Montserrat; font-size: 12pt">.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">致力</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >提供方便且功能正常的</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式，但不以任何方式保證</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式或伺服器不會出現錯誤或可持續運作或</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式或伺服器並無病毒或其他有害組件。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">4</span
          ><span style="font-family: Montserrat; font-size: 12pt">.4</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">不</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >以任何方式保證透過</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式進行視像通話的質素，因為這取決於各種因素，例如用戶的硬件、互聯網連接狀況等。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">4</span
          ><span style="font-family: Montserrat; font-size: 12pt">.5</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">儘管</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">將</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >盡合理努力維護</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式，</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">並不</span
          ><span style="font-family: Montserrat; font-size: 12pt">承諾提供</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式的支援或維護服務。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">4</span
          ><span style="font-family: Montserrat; font-size: 12pt">.6</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >若閣下使用</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >應用程式導致需要維修或更換任何財產、材料、設備或數據，</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">不會</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >以任何方式對有關任何損失或損害的任何費用或開支負責。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >&nbsp;</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >1</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >5</span
          ><span
            style="font-family: Montserrat; font-size: 15pt; font-weight: bold"
            >. 一般條款</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">5</span
          ><span style="font-family: Montserrat; font-size: 12pt">.1</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">「</span
          ><span
            style="font-family: Montserrat; font-size: 12pt; font-weight: bold"
            >工作日</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >」是指星期一至星期</span
          ><span style="font-family: Montserrat; font-size: 12pt">五</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >，香港的公眾假期除外。</span
          ><span style="font-family: Montserrat; font-size: 12pt"> </span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">5</span
          ><span style="font-family: Montserrat; font-size: 12pt">.2</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則中使用的所有人稱代詞，不論是用作男性、女性或中性性別，</span
          ><span style="font-family: Montserrat; font-size: 12pt">均應包括</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >其他性別，並且單數應包括眾數，反之亦然。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">5</span
          ><span style="font-family: Montserrat; font-size: 12pt">.3</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">對於</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">及</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >合理控制以外的原因，導致未能履行</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則項下</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >任何義務，</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">及</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >均毋須承擔責任或被視為違約。該等原因或情況包括但不限於天災、政府行為、火災、水災、流行病、檢疫限制、罷工、公民動亂、勞動力或物資短缺、貨物禁運、異常惡劣天氣、電力故障、電訊或互聯網主幹中斷、互聯網接入供應商故障，或本公司無法控制的其他類似原因，</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">及</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >均毋須對該等原因直接或間接引致的一般、特別、間接或後隨損失、開支或損害承擔責任。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">5</span
          ><span style="font-family: Montserrat; font-size: 12pt">.4</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">有關</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >或其下服務的任何爭議，</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt">的</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >決定應為最終、具約束力及具決定性。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">5</span
          ><span style="font-family: Montserrat; font-size: 12pt">.5</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">若</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則的任何部分被裁定為無法執行，則無法執行的部分應盡可能被賦予最大程度之效力，而其餘部分將維持十足效力及生效。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">5</span
          ><span style="font-family: Montserrat; font-size: 12pt">.6</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">除</span
          ><span style="font-family: Montserrat; font-size: 12pt">騰創</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >的聯營方外，並非</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >登記用戶的其他人士或實體均無權根據《合約（第三者權利）條例》（香港法例第623章）執行</span
          ><span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則的任何條款，亦不享有與此相關的任何權利及／或利益。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">1</span
          ><span style="font-family: Montserrat; font-size: 12pt">5</span
          ><span style="font-family: Montserrat; font-size: 12pt">.</span
          ><span style="font-family: Montserrat; font-size: 12pt">7</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">IMeddy</span
          ><span style="font-family: Montserrat; font-size: 12pt"
            >條款及細則受香港法律管轄。閣下不可撤銷地接受香港法院專屬司法管轄。</span
          >
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt">&nbsp;</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Montserrat; font-size: 12pt"
            >版本：2021年</span
          ><span style="font-family: Montserrat; font-size: 12pt">4</span
          ><span style="font-family: Montserrat; font-size: 12pt">月</span>
        </p>
        <p style="margin: 0pt; orphans: 0; widows: 0">
          <span style="font-family: Calibri; font-size: 12pt">&nbsp;</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
//保留期待頁面
import { mapMutations } from "vuex";
export default {
  components: {},
  data() {
    return {
      statusBarHeight: "0px",
    };
  },
  methods: {
    ...mapMutations({
      next_bill: "reservation/next_bill",
    }),
    //設置返回
    onback() {
      this.next_bill();
    },
  },
  created: function () {
    if (this.$store.state.statusBarHeight > 0) {
      this.statusBarHeight = this.$store.state.statusBarHeight - 25 + "px";
    }
  },
};
</script>

<style lang="scss" scoped>
.span-tx {
  font-size: 16px;
}
// 这里是禁止长按选中文字
* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.back-span {
  margin-top: 10px;
  color: white;
  text-align: center;
  width: 88%;
  padding: 8px;
  border-radius: 8px;
  height: auto;
  background-color: #54cbb7;
  font-size: 17px;
}
.container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  color: #828282;
  font-weight: 400;
  flex-direction: column;
  font-size: 19px;
  color: #000000;
}
.back-button {
  width: 100%;
  border-radius: 2px;
  height: auto;
  color: white;
  font-size: 26px;
}
.top-btn {
  height: auto;
  width: 100%;
  padding: 15px;
  background-color: #60c5a7;
  top: 0px;
  position: fixed; //定位之后禁止滚动
}
.text-body {
  width: 100%;
  height: auto;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 90px;
  overflow: hidden;
}
</style>

